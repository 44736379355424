import React,{useState, useEffect} from "react"

import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";

var strapi_url = process.env.GATSBY_STRAPI_SRC;

export const ImageModule = (props) => {

    if(props.ImageSrc && props.ImageSrc.url){
        var find_ggfx_url =  (props.ImageSrc.url).indexOf('https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com'), 
        image_url = '';
        //console.log("find_ggfx_url",find_ggfx_url, props.ImageSrc.url);
        if(find_ggfx_url === 0){
            image_url = props.ImageSrc.url;
        } else{
            image_url = strapi_url+props.ImageSrc.url;
            //image_url = props.ImageSrc.url;
        }

        //console.log("article_id", props.article_id);

        // console.log("processedImages 1", props.imagetransforms, image_url, props.imagename);
        return(
            <>
            {
                props.imagename ? (
                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename={props.imagename} attr={{className:props.classNames, alt: props.ImageSrc.alternativeText }} imagetransformresult={props.imagetransforms} id={props.article_id} />
                ) : (
                    <img className={props.classNames} src={image_url} alt={props.ImageSrc.alternativeText} />
                )
            }
            {/* <img className={props.classNames} src={image_url} alt={props.ImageSrc.alternativeText} /> */}
            
            </>
        )
    } else{
        return '';
    }
    
}

export const SourceBlock = (props) => {
    return(
        <source media={props.media} srcset={props.ImageSrc.url} />
    )
}
